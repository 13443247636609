import firebase from "firebase";
import "firebase/database";

//PROD FIREBASE CONFIG
const config = {
  apiKey: "AIzaSyDUIMFHe3vlHXtE_yXd2Suspxj-y7pkXHY",
  authDomain: "cad-prod-6ce35.firebaseapp.com",
  databaseURL:
    "https://cad-prod-6ce35-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "cad-prod-6ce35",
  storageBucket: "cad-prod-6ce35.appspot.com",
  messagingSenderId: "386574374770",
  appId: "1:386574374770:web:4266a65e505f7f909d6feb",
  measurementId: "G-02P3Z74Z4F",
};

//UAT FIREBASE CONFIG
// const config = {
//     apiKey: "AIzaSyBv8CuLN9cc4RVsQkQCxbfcrHB0TYXqw5o",
//     authDomain: "cad-project-c8602.firebaseapp.com",
//     databaseURL: "https://cad-project-c8602.firebaseio.com",
//     projectId: "cad-project-c8602",
//     storageBucket: "cad-project-c8602.appspot.com",
//     messagingSenderId: "871137525870",
//     appId: "1:871137525870:web:8635aecd25a7ef22f4667f",
//     measurementId: "G-48DK1ZE3HN"
//   };

//DEV FIREBASE CONFIG
// const config = {
//   apiKey: "AIzaSyC7UZ9P7Ldj84NS9FNjyoUv8Jsrw3tg35A",
//   authDomain: "cad-testdev.firebaseapp.com",
//   databaseURL: "https://cad-testdev-default-rtdb.firebaseio.com",
//   projectId: "cad-testdev",
//   storageBucket: "cad-testdev.appspot.com",
//   messagingSenderId: "360704551850",
//   appId: "1:360704551850:web:a58d28c91d69fa633759d3",
//   measurementId: "G-B7EMB2DPWF",
// };

firebase.initializeApp(config);
export default firebase;
